

//import { Link }
//from "gatsby"



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import EmployeeIcon from "../img/S_dla_pracownika_11/iko_pracownik.png";
import EmployeeIcon2 from "../img/S_dla_pracownika_11/iko_chmura.png";
import EmployeeIcon3 from "../img/icon/iko_video.png";


import PlayPNG from "../img/mobile/store/play.png";
import apple from "../img/mobile/store/apple.png";
import arrow from "../img/mobile/store/arrow.png";
import arrown from "../img/mobile/store/arrown.png";






import {Link, Trans, useTranslation, useI18next}
from 'gatsby-plugin-react-i18next';


export default function Header(props) {

    if (typeof document !== `undefined`) {



        document.storestart = function () {
            document.play = document.getElementById("play");
            document.appstore = document.getElementById("appstore");
            document.shop_ar = document.getElementById("shop_ar");
            document.appstore_ar = document.getElementById("appstore_ar");
            document.play_ar = document.getElementById("play_ar");

            document.play.onclick = function () {
                document.play.style.border = "5px solid rgb(109, 95, 74)";
                document.play_ar.style.transition = "0.1s";
                document.play_ar.style.transform = "translateX(22px)";
                document.location.href = 'https://play.google.com/store/apps/details?id=com.comarch.hrmandroid&hl=pl&gl=US';

            }



            document.appstore.onclick = function () {
                document.appstore.style.border = "5px solid rgb(0, 0, 0)";
                document.appstore_ar.style.transition = "0.1s";
                document.appstore_ar.style.transform = "translateX(22px)";
                document.location.href = 'https://apps.apple.com/pl/app/comarch-hrm/id1353825091?l=pl';

            }



        }

        setTimeout(function () {
            document.storestart()
        }, 333);




    }

    return (
            <React.Fragment>

                <section class="employee firstSection ePracownik">
                    <h1>ePracownik</h1>
                    <div class="link">
                        <a href='/ePracownik.pdf'>Zobacz&nbsp;instrukcję&nbsp;uruchomienia</a>
                    </div>


                    <div class="Eicons">
                        <a href='https://edokument.instanta.pl/ePracownik'>
                            <div class="Eicon">
                                <div>
                                    <img loading="lazy" src={EmployeeIcon} alt="Instanta Epracownik" />
                                </div>
                                <p>
                                    Otwórz w tej przeglądarce
                                </p>
                            </div>
                        </a>
                    </div>







                    <div class="Mobileapp">
                        <div class="napis">Pobierz aplikację <br />na telefon</div>
                        <div class="play" id="play"  >
                            <div>
                                <img src={PlayPNG} class="shopi" />
                            </div>
                            <div>
                                <img src={arrow} class="arrow" id="play_ar" />
                            </div>
                        </div>
                        <div class="appstore" id="appstore" >
                            <div>
                                <img src={apple} class="shopi" />
                            </div>
                            <div>
                                <img src={arrow} class="arrow" id="appstore_ar" />
                            </div>

                        </div>

                    </div>












                </section>



            </React.Fragment>

            )


}
